import { default as indexGFo85QeR0VMeta } from "/www/wwwroot/lnf_frontend/pages/dashboard/index.vue?macro=true";
import { default as messagesLD99L3lR8DMeta } from "/www/wwwroot/lnf_frontend/pages/dashboard/messages.vue?macro=true";
import { default as settingsvCt5A93MxDMeta } from "/www/wwwroot/lnf_frontend/pages/dashboard/settings.vue?macro=true";
import { default as usersGxzlHm3nakMeta } from "/www/wwwroot/lnf_frontend/pages/dashboard/users.vue?macro=true";
import { default as indexunCJ7MTPdNMeta } from "/www/wwwroot/lnf_frontend/pages/dashboard/website-data/index.vue?macro=true";
import { default as mediadUfdX1K6OYMeta } from "/www/wwwroot/lnf_frontend/pages/dashboard/website-data/media.vue?macro=true";
import { default as _91id_935ZQrf5uMedMeta } from "/www/wwwroot/lnf_frontend/pages/dashboard/website-data/pages/[id].vue?macro=true";
import { default as indexrDoL3H6QeOMeta } from "/www/wwwroot/lnf_frontend/pages/dashboard/website-data/pages/index.vue?macro=true";
import { default as formsuQ5s53iOoMeta } from "/www/wwwroot/lnf_frontend/pages/form.vue?macro=true";
import { default as indexmy59dDqobLMeta } from "/www/wwwroot/lnf_frontend/pages/index.vue?macro=true";
import { default as loginv6kulJHE1eMeta } from "/www/wwwroot/lnf_frontend/pages/login.vue?macro=true";
export default [
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexGFo85QeR0VMeta || {},
    component: () => import("/www/wwwroot/lnf_frontend/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-messages",
    path: "/dashboard/messages",
    meta: messagesLD99L3lR8DMeta || {},
    component: () => import("/www/wwwroot/lnf_frontend/pages/dashboard/messages.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings",
    path: "/dashboard/settings",
    meta: settingsvCt5A93MxDMeta || {},
    component: () => import("/www/wwwroot/lnf_frontend/pages/dashboard/settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-users",
    path: "/dashboard/users",
    meta: usersGxzlHm3nakMeta || {},
    component: () => import("/www/wwwroot/lnf_frontend/pages/dashboard/users.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-website-data",
    path: "/dashboard/website-data",
    meta: indexunCJ7MTPdNMeta || {},
    component: () => import("/www/wwwroot/lnf_frontend/pages/dashboard/website-data/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-website-data-media",
    path: "/dashboard/website-data/media",
    meta: mediadUfdX1K6OYMeta || {},
    component: () => import("/www/wwwroot/lnf_frontend/pages/dashboard/website-data/media.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-website-data-pages-id",
    path: "/dashboard/website-data/pages/:id()",
    meta: _91id_935ZQrf5uMedMeta || {},
    component: () => import("/www/wwwroot/lnf_frontend/pages/dashboard/website-data/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-website-data-pages",
    path: "/dashboard/website-data/pages",
    meta: indexrDoL3H6QeOMeta || {},
    component: () => import("/www/wwwroot/lnf_frontend/pages/dashboard/website-data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "form",
    path: "/form",
    component: () => import("/www/wwwroot/lnf_frontend/pages/form.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexmy59dDqobLMeta || {},
    component: () => import("/www/wwwroot/lnf_frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginv6kulJHE1eMeta || {},
    component: () => import("/www/wwwroot/lnf_frontend/pages/login.vue").then(m => m.default || m)
  }
]